import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getActiveOffers } from "../../api/user";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OfferPopup from "../../components/user/OfferPopup";
import { recordAnalyticsEvent } from "../../api/analytics";
import BottomMenu from "../../components/user/BottomMenu";
import LoadingOverlay from "../../components/LoadingOverlay";
import Offercard from "../../components/user/offerCard";
import swiggy from "../../pages/assets/Group 18291.svg";
import zomato from "../../pages/assets/image 52.svg";
import UserHeader from "../../components/user/UserHeader";
import AdsComponent from "../../components/GoogleAds";

const CafeActiveOffers = () => {
  const [offers, setOffers] = useState([]);
  const [cafeDetails, setCafeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [cafeId, setCafeId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const cafeData = sessionStorage.getItem("OG_cafe");
    if (!cafeData) {
      toast.error("Cafe data not found. Please try again.");
      navigate("/");
      return;
    }

    try {
      const cafeObj = JSON.parse(cafeData);
      if (!cafeObj._id) {
        throw new Error("Invalid cafe data");
      }
      setCafeDetails(cafeObj);
      setCafeId(cafeObj._id);
      fetchOffers(cafeObj._id);
    } catch (error) {
      console.error("Error parsing cafe data:", error);
      toast.error("Invalid cafe data. Please try again.");
      navigate("/");
    }
  }, [navigate]);

  const fetchOffers = async (id) => {
    try {
      setLoading(true);
      const result = await getActiveOffers(id);
      setOffers(result.data.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)) || []);
    } catch (error) {
      console.error("Error fetching active offers:", error);
      toast.error("Failed to fetch active offers");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-4">
        <LoadingOverlay />
      </div>
    );
  }

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    const offerLink = `${window.location.origin}/cafe/offer/${offer.id}`;
    recordAnalyticsEvent("offer_click", "ActiveOffers", {
      offer_id: offer.id,
      offer_title: offer.title,
      offer_type: offer.type,
      link: offerLink,
    });
  };

  if (loading) {
    return (
      <div className="text-center py-4">
        <LoadingOverlay />
      </div>
    );
  }
  console.log("Active Cafe Offers: ", offers)
  return (
    <>
      <UserHeader
        cafeName={cafeDetails?.name}
        cafeId={cafeId}
      />
      <AdsComponent dataAdSlot="4795849536" />
      <div className="container mx-auto px-4 pt-2 pb-8 mb-20">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl font-bold mb-6">Active Offers</h1>
          <Link
            to="/cafe/all-offers"
            className="text-2xl px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 shadow-[2px_3px_0px_0px_rgba(0,0,0,1)]"
          >
            More Offers
          </Link>
        </div>
        {offers.length > 0 ? (
          <div className="flex flex-col items-center gap-4">
            {offers.map((offer, index) => (
              <div
                key={index}
                className="w-full max-w-md"
                onClick={() => handleOfferClick(offer)}
              >
                {offer.type === "card" ? (
                  <Offercard
                    offer={offer}
                    cafeName={cafeDetails.name}
                  />
                ) : (
                  <div className="border-2 border-white px-2 pt-1 rounded-lg shadow-lg">
                    <div className="w-full aspect-video rounded-lg shadow-md overflow-hidden">
                      <img
                        src={
                          offer.posterImage
                            ? `data:image/webp;base64,${offer.posterImage}`
                            : `${offer.imageUri}`
                        }
                        alt={`Offer ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex items-center justify-center gap-x-6">
                      {offer.addToSwiggy === "true" && (
                        <img
                          src={swiggy}
                          alt="Swiggy"
                          className="w-24 h-24"
                        />
                      )}
                      {offer.addToZomato === "true" && (
                        <img
                          src={zomato}
                          alt="Zomato"
                          className="w-24 h-24"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No active offers at the moment.</p>
        )}

        {selectedOffer && (
          <OfferPopup
            offer={selectedOffer}
            onClose={() => setSelectedOffer(null)}
            cafeName={cafeDetails.name}
          />
        )}
        <ToastContainer />
      </div>
      <BottomMenu />
    </>
  );
};

export default CafeActiveOffers;
