import React, { useState, useEffect } from 'react';
import { Layout, Menu, Card, Row, Col, Table, Button, Modal, message, Input, Form } from 'antd';
import { UserOutlined, WalletOutlined, LogoutOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const API_URL = process.env.REACT_APP_BASE_URL;


const { Header, Sider, Content } = Layout;

const CompanyAdminDashboard = () => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMenu, setSelectedMenu] = useState('1');
    const [rechargeAmount, setRechargeAmount] = useState();
    const [isAddEmployeeModalVisible, setIsAddEmployeeModalVisible] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/company/employees`, {
                headers: {
                    'Authorization': localStorage.getItem('company_admin_token')
                }
            });
            if (response.data.success) {
                setEmployees(response.data.data);
            } else {
                message.error(response.data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error fetching employees:', error);
            message.error('Failed to fetch employees');
        } finally {
            setLoading(false);
        }
    };

    const handleAddEmployee = async (values) => {
        try {
            await axios.post(`${API_URL}/api/company/employee/add`, values, {
                headers: {
                    'Authorization': localStorage.getItem('company_admin_token')
                }
            });
            message.success('Employee added successfully');
            setIsAddEmployeeModalVisible(false);
            form.resetFields();
            fetchEmployees();
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to add employee');
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: 'Employee Code',
            dataIndex: 'employee_code',
            key: 'employee_code',
        },
        {
            title: 'Wallet Balance',
            dataIndex: 'wallet_balance',
            key: 'wallet_balance',
            render: (balance) => `₹${Number(balance)?.toFixed(2) || '0.00'}`,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() => handleRechargeWallet(record._id)}
                >
                    Recharge Wallet
                </Button>
            ),
        }
    ];

    const AddEmployeeModal = () => (
        <Modal
            title="Add New Employee"
            visible={isAddEmployeeModalVisible}
            onCancel={() => setIsAddEmployeeModalVisible(false)}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleAddEmployee}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please enter name' }]}
                >
                    <Input placeholder="Enter employee name" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: 'Please enter email' },
                        { type: 'email', message: 'Please enter a valid email' }
                    ]}
                >
                    <Input placeholder="Enter email" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                        { required: true, message: 'Please enter phone number' },
                        { pattern: /^\d{10}$/, message: 'Please enter a valid 10-digit phone number' }
                    ]}
                >
                    <Input placeholder="Enter phone number" />
                </Form.Item>

                <Form.Item
                    name="employee_code"
                    label="Employee Code"
                >
                    <Input placeholder="Enter employee code" />
                </Form.Item>

                <Form.Item
                    name="department"
                    label="Department"
                >
                    <Input placeholder="Enter department" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add Employee
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );


    const handleLogout = () => {
        localStorage.removeItem('company_admin_token');
        navigate('/company-admin/login');
    };

    const handleRechargeWallet = (employeeId) => {
        Modal.confirm({
            title: 'Recharge Wallet',
            content: (
                <div>
                    <Input
                        type="number"
                        placeholder="Enter amount"
                        min={0}
                        onChange={(e) => setRechargeAmount(e.target.value)}
                    />
                </div>
            ),
            onOk: async () => {
                try {
                    await axios.post(`${API_URL}/api/company/wallet/recharge`, {
                        employeeId,
                        amount: rechargeAmount
                    }, {
                        headers: {
                            'Authorization': localStorage.getItem('company_admin_token')
                        }
                    });
                    message.success('Wallet recharged successfully');
                    fetchEmployees();
                } catch (error) {
                    message.error('Failed to recharge wallet');
                }
            }
        });
    };

    return (
      <Layout
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
          position: "fixed",
          left: 0,
        }}
      >
        <Sider>
          <div className="logo p-4">
            <h2 className="text-white text-xl">Company Admin</h2>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenu]}
            items={[
              {
                key: "1",
                icon: <UserOutlined />,
                label: "Employees",
                onClick: () => setSelectedMenu("1"),
              },
              {
                key: "2",
                icon: <WalletOutlined />,
                label: "Transactions",
                onClick: () => setSelectedMenu("2"),
              },
              {
                key: "3",
                icon: <LogoutOutlined />,
                label: "Logout",
                onClick: handleLogout,
              },
            ]}
          />
        </Sider>
        <Layout>
          <Header className="bg-white p-0 flex justify-end items-center pr-6">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsAddEmployeeModalVisible(true)}
            >
              Add Employee
            </Button>
          </Header>
          <Content className="p-6">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Card>
                  <h3>Total Employees</h3>
                  <p className="text-2xl">{employees.length}</p>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <h3>Total Balance</h3>
                  <p className="text-2xl">
                    ₹
                    {employees
                      .reduce(
                        (total, emp) =>
                          total + (Number(emp.wallet_balance) || 0),
                        0
                      )
                      .toFixed(2)}
                  </p>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <h3>Active Employees</h3>
                  <p className="text-2xl">
                    {employees.filter((emp) => emp.is_active).length}
                  </p>
                </Card>
              </Col>
            </Row>

            <Card className="mt-6">
              <Table
                columns={columns}
                dataSource={employees}
                loading={loading}
                rowKey="_id"
              />
            </Card>
          </Content>
        </Layout>
        <AddEmployeeModal />
      </Layout>
    );
};


export default CompanyAdminDashboard;