import { useState, useEffect } from "react";
import Pusher from "pusher-js";

const usePusherService = (cafeId, eventName = "NEW_ORDER") => {
  const [pusher, setPusher] = useState(null);
  const [channel, setChannel] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [newOrders, setNewOrders] = useState([]);

  useEffect(() => {
    // Do nothing if cafeId is not provided
    if (!cafeId) return;
    // Check if Pusher instance already exists
    if (pusher) {
      console.log("Pusher connection already exists. Skipping initialization.");
      return;
    }

    console.log("Initializing Pusher for cafeId:", cafeId);

    // Initialize Pusher
    const pusherInstance = new Pusher("1d3f095e03fe6ac335c9", {
      cluster: "ap2",
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
    });

    // Connection event listeners
    pusherInstance.connection.bind("connected", () => {
      console.log("Connected to Pusher");
      setIsConnected(true);
      setError(null);
    });

    pusherInstance.connection.bind("error", (err) => {
      console.error("Pusher connection error:", err);
      setError(`Connection error: ${err.message}`);
      setIsConnected(false);
    });

    // Subscribe to the channel
    const channelInstance = pusherInstance.subscribe(cafeId);

    channelInstance.bind("pusher:subscription_succeeded", () => {
      console.log(`Successfully subscribed to channel: ${cafeId}`);
      setChannel(channelInstance);
    });

    channelInstance.bind("pusher:subscription_error", (err) => {
      console.error("Subscription error:", err);
      setError(`Subscription error: ${err.message}`);
    });

    // Bind custom event listener
    channelInstance.bind(eventName, (data) => {
      setNewOrders((prevOrders) => [...prevOrders, data]);
      console.log(`Received event: ${eventName}`, data);
    });

    // Save Pusher instance in state
    setPusher(pusherInstance);

    return () => {
      // Cleanup on unmount or dependency change
      console.log("Cleaning up Pusher connection");
      channelInstance.unbind_all();
      pusherInstance.unsubscribe(cafeId);
      pusherInstance.disconnect();
    };
  }, [cafeId, eventName]);

  const sendMessage = async (message, cafeId, eventName) => {
    console.log("Sending message:", message, "to cafe:", cafeId, "with event:", eventName);
    try {
      const response = await fetch("http://localhost:5001/send-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, cafeId, eventName }),
      });
      if (!response.ok) {
        throw new Error("Failed to send message");
      }
      console.log("Message sent successfully");
    } catch (error) {
      console.error("Error sending message:", error);
      setError(`Error sending message: ${error.message}`);
    }
  };

  return { sendMessage, isConnected, error, newOrders };
};

export default usePusherService;