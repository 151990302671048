import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WorkspaceLayout from "./workspaceLayout";
import { Button, Card, Table, Typography } from "antd";
import SalesGraph from "./SalesGraph";

const { Title } = Typography;

const WorkspaceBranchBoard = () => {
  const [branchDetails, setBranchDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { branchId } = useParams();

  useEffect(() => {
    const fetchBranchDetails = async (branchId) => {
      try {
        setLoading(true);
        const token = localStorage.getItem("workspaceAdminToken");
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/workspace/branch/${branchId}`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setBranchDetails(data.data);
        }
      } catch (error) {
        console.error("Error fetching branch details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBranchDetails(branchId);
  }, []);

  const companyColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  const cafeColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => navigate(`/workspace/${record._id}/report`)}
        >
          Check Details
        </Button>
      ),
    },
  ];

  return (
    <WorkspaceLayout>
      <div className="w-full flex items-center gap-x-2 mx-8 my-8">
        <SalesGraph totalSales={11310} />
        <div
          className="bg-[#FFF2AF] p-4 rounded-lg shadow-md max-w-sm mx-2 border border-[#000000]"
          style={{ boxShadow: "3px 3px 0px 0px #000000" }}
        >
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-4xl font-bold">15</h2>
              <p className="text-gray-600 text-sm">Total Feedback</p>
            </div>
            <div>
              <h2 className="text-4xl font-bold">4.2</h2>
              <p className="text-gray-600 text-sm">Avg. Rating</p>
            </div>
          </div>
          <hr className="border-[#FFD700] mb-4" />
          <div className=" mb-2">
            <p className="text-xs ">Detailed</p>
          </div>
          <div className="grid grid-cols-3 gap-4 text-center">
            <div>
              <div className="text-sm font-bold flex gap-1">
                {" "}
                <div className="">53</div>{" "}
                <spam className="font-medium text-sm">Positive</spam>
              </div>
              <div className="flex gap-1">
                <div className="text-2xl">😀</div>
                <div className="text-xl font-bold">28.9%</div>
              </div>
            </div>
            <div>
              <div className="text-sm font-bold flex gap-1">
                <div className="">10</div>{" "}
                <spam className="font-medium">Negative</spam>
              </div>

              <div className="flex gap-1">
                {" "}
                <div className="text-2xl">😞</div>
                <div className="text-xl font-bold">0.23%</div>
              </div>
            </div>
            <div>
              <div className="text-sm font-bold flex gap-1">
                <div className="">45</div>{" "}
                <spam className="font-medium">Neutral</spam>
              </div>
              <div className="flex gap-1">
                {" "}
                <div className="text-2xl">😐</div>
                <div className="text-xl font-bold">53.6%</div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      {branchDetails ? (
        <div className="w-full flex flex-col justify-start space-y-6 mx-4">
          <div className="flex flex-col justify-start">
            <Title
              level={3}
              style={{ marginLeft: "30px" }}
            >
              Active Cafes
            </Title>
            <Card
              title="Cafes"
              size="small"
              style={{
                margin: "10px 30px",
              }}
            >
              <Table
                columns={cafeColumns}
                dataSource={branchDetails.cafes}
                rowKey="_id"
                pagination={false}
              />
            </Card>
          </div>
          <div className="flex flex-col justify-start">
            <Title
              level={3}
              style={{ marginLeft: "30px", marginBottom: "0" }}
            >
              Offices Using OrderG
            </Title>
            <Card
              size="small"
              style={{
                margin: "10px 30px",
              }}
            >
              <Table
                columns={companyColumns}
                dataSource={branchDetails.companies}
                rowKey="_id"
                pagination={false}
              />
            </Card>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </WorkspaceLayout>
  );
};

export default WorkspaceBranchBoard;
