import React from "react";
import { Layout, Menu, Card, Table, Typography, Button, Modal } from "antd";
import OrderGLogo from "../../pages/assets/workspace-orderg-logo.png";
import { LogoutOutlined } from "@ant-design/icons";

const { Header, Content, Sider } = Layout;

const WorkspaceLayout = ({ children, handleLogout }) => {
  return (
    <Layout
      style={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
        left: 0,
      }}
    >
      <Header
        className="flex items-center justify-between px-6"
        style={{
          backgroundColor: "white",
        }}
      >
        <img
          src={OrderGLogo}
          alt="OrderG Logo"
        />
        <Button
          type="primary"
          danger
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Header>
      <div>{children}</div>
    </Layout>
  );
};

export default WorkspaceLayout;
