import React, { useEffect, useState } from "react";
import { Table, Button, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { getKOTs } from "../api/pos";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SaleReport = () => {
  const [kots, setKots] = useState([]);
  const [filteredKots, setFilteredKots] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [filter, setFilter] = useState();

  useEffect(() => {
    const fetchKOTs = async () => {
      try {
        const response = await getKOTs();
        console.log("Fetched KOTs:", response);
        setKots(response.data);
        setFilteredKots(response.data);
        calculateTotalAmount(response.data);
      } catch (err) {
        console.error("Failed to fetch KOTs:", err);
      }
    };
    fetchKOTs();
  }, []);

  const calculateTotalAmount = (data) => {
    const total = data.reduce((sum, kot) => {
      const kotTotal = kot.items.reduce((itemSum, item) => {
        return itemSum + (item.price || 0) * item.quantity;
      }, 0);
      return sum + kotTotal;
    }, 0);
    setTotalAmount(total);
  };

  const applyFilter = (filter) => {
    const now = dayjs();
    let filteredData = [];

    switch (filter) {
      case "today":
        filteredData = kots.filter((kot) =>
          dayjs(kot.created_at).isSame(now, "day")
        );
        break;
      case "yesterday":
        filteredData = kots.filter((kot) =>
          dayjs(kot.created_at).isSame(now.subtract(1, "day"), "day")
        );
        break;
      case "thisWeek":
        filteredData = kots.filter((kot) =>
          dayjs(kot.created_at).isSame(now, "week")
        );
        break;
      case "lastWeek":
        filteredData = kots.filter((kot) =>
          dayjs(kot.created_at).isSame(now.subtract(1, "week"), "week")
        );
        break;
      case "lastMonth":
        filteredData = kots.filter((kot) =>
          dayjs(kot.created_at).isSame(now.subtract(1, "month"), "month")
        );
        break;
      case "lastYear":
        filteredData = kots.filter((kot) =>
          dayjs(kot.created_at).isSame(now.subtract(1, "year"), "year")
        );
        break;
      default:
        filteredData = kots;
    }

    setFilteredKots(filteredData);
    calculateTotalAmount(filteredData);
  };

  const handleDateRangeChange = (dates, action) => {
    if (action === "show") {
      if (dates && dates.length === 2) {
        const [startDate, endDate] = dates;
        const filteredData = kots.filter(
          (kot) =>
            dayjs(kot.created_at).isAfter(startDate) &&
            dayjs(kot.created_at).isBefore(endDate)
        );
        setFilteredKots(filteredData);
        calculateTotalAmount(filteredData);
      }
    } else if (action === "clear") {
      setFilteredKots(kots);
      calculateTotalAmount(kots);
    }
  };

  const columns = [
    {
      title: "KOT ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => dayjs(date).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      render: (items) =>
        items.map((item) => `${item.name} (${item.quantity})`).join(", "),
    },
    {
      title: "Total",
      dataIndex: "items",
      key: "total",
      render: (items) =>
        items.reduce((sum, item) => sum + (item.price || 0) * item.quantity, 0),
    },
  ];

  return (
    <div className="p-4">
      <h1 className="text-lg font-semibold mb-4">Sales Report</h1>

      <div className="flex items-center gap-4 mb-6">
        <Select
          //   defaultValue="today"
          onChange={(value) => {
            setFilter(value);
            applyFilter(value);
          }}
          className="w-40"
        >
          <Option value="today">Today</Option>
          <Option value="yesterday">Yesterday</Option>
          <Option value="thisWeek">This Week</Option>
          <Option value="lastWeek">Last Week</Option>
          <Option value="lastMonth">Last Month</Option>
          <Option value="lastYear">Last Year</Option>
        </Select>

        <RangePicker
          onChange={(dates) => handleDateRangeChange(dates, "show")}
          className="w-full max-w-md"
        />

        {/* <Button
          type="primary"
          onClick={() => {
            setFilter();
            setFilteredKots(kots);
            calculateTotalAmount(kots);
            handleDateRangeChange("", "clear");
          }}
        >
          Clear Filters
        </Button> */}
      </div>

      <div className="text-lg font-semibold mb-4">
        Total Amount:{" "}
        <span className="text-green-600">₹{totalAmount.toFixed(2)}</span>
      </div>

      <Table
        dataSource={filteredKots.map((kot) => ({
          key: kot.id,
          id: kot.id,
          created_at: kot.created_at,
          items: kot.items,
        }))}
        columns={columns}
        bordered
        className="rounded-md shadow-md"
      />
    </div>
  );
};

export default SaleReport;
