import { useNavigate } from "react-router-dom";

export default function OrderConfirmation() {
    const navigate = useNavigate();
  return (
    <div className="min-h-screen flex items-center justify-center bg-white p-4">
      <div className="text-center max-w-md mx-auto space-y-6">
        {/* Check mark icon */}
        <div className="w-24 h-24 mx-auto bg-emerald-200 rounded-full flex items-center justify-center">
          <svg
            className="w-12 h-12 text-emerald-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>

        {/* Thank you message */}
        <h1 className="text-4xl font-bold text-teal-700">Thank You!</h1>

        {/* Subtitle */}
        <p className="text-gray-600 text-lg max-w-xs mx-auto">
          Your order has been placed successfully, #0027.
          We Will start preparing your order. Thank you for choosing OrderG!
        </p>

        {/* Back button */}
        <button
          className="bg-orange-500 text-white px-8 py-3 rounded-md font-medium hover:bg-orange-600 transition-colors"
          onClick={() => navigate("/cafe/menu")}
        >
          Back to Menu
        </button>
      </div>
    </div>
  );
}
