import React from 'react'
import { useParams } from 'react-router-dom'
import WorkspaceLayout from './workspaceLayout'
import SalesGraph from './SalesGraph'
import SaleReport from '../salesReport'

const WorkspaceCafeReport = () => {
    const { cafeId } = useParams()
  return (
    <WorkspaceLayout>
      <div className="w-full flex items-center gap-x-2 mx-8 my-8">
        <SalesGraph totalSales={11310} />
        <div
          className="bg-[#FFF2AF] p-4 rounded-lg shadow-md max-w-sm mx-2 border border-[#000000]"
          style={{ boxShadow: "3px 3px 0px 0px #000000" }}
        >
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-4xl font-bold">15</h2>
              <p className="text-gray-600 text-sm">Total Feedback</p>
            </div>
            <div>
              <h2 className="text-4xl font-bold">4.2</h2>
              <p className="text-gray-600 text-sm">Avg. Rating</p>
            </div>
          </div>
          <hr className="border-[#FFD700] mb-4" />
          <div className=" mb-2">
            <p className="text-xs ">Detailed</p>
          </div>
          <div className="grid grid-cols-3 gap-4 text-center">
            <div>
              <div className="text-sm font-bold flex gap-1">
                {" "}
                <div className="">53</div>{" "}
                <spam className="font-medium text-sm">Positive</spam>
              </div>
              <div className="flex gap-1">
                <div className="text-2xl">😀</div>
                <div className="text-xl font-bold">28.9%</div>
              </div>
            </div>
            <div>
              <div className="text-sm font-bold flex gap-1">
                <div className="">10</div>{" "}
                <spam className="font-medium">Negative</spam>
              </div>

              <div className="flex gap-1">
                {" "}
                <div className="text-2xl">😞</div>
                <div className="text-xl font-bold">0.23%</div>
              </div>
            </div>
            <div>
              <div className="text-sm font-bold flex gap-1">
                <div className="">45</div>{" "}
                <spam className="font-medium">Neutral</spam>
              </div>
              <div className="flex gap-1">
                {" "}
                <div className="text-2xl">😐</div>
                <div className="text-xl font-bold">53.6%</div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <SaleReport />
      <div className='mb-10'></div>
    </WorkspaceLayout>
  );
}

export default WorkspaceCafeReport