import React from "react";

const SalesGraph = ({ totalSales }) => {
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(totalSales);
  return (
    <div
      className="h-64 bg-[#9277FF] rounded-lg shadow-lg w-full max-w-sm p-3"
      style={{ boxShadow: "3px 3px 0px 0px #000000" }}
    >
      <div className="p-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          {formattedAmount}
        </h2>
        <p className="text-sm text-black mt-1">Total Sales</p>
      </div>
      <div className="h-20 bg-purple-50 relative overflow-hidden">
        <div className="absolute inset-0 flex items-end">
          <div
            className="w-full h-3/5 bg-gradient-to-t from-purple-500/50 to-purple-500/0"
            style={{
              clipPath:
                "polygon(0% 100%, 10% 80%, 20% 90%, 30% 70%, 40% 85%, 50% 60%, 60% 75%, 70% 50%, 80% 65%, 90% 45%, 100% 55%, 100% 100%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SalesGraph;
