import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: 0,
  transactions: [],
  loading: false,
  error: null,
  lastTransaction: null
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    addTransaction: (state, action) => {
      state.transactions.unshift(action.payload);
      state.lastTransaction = action.payload;
      if (action.payload.type === 'credit') {
        state.balance += action.payload.amount;
      } else {
        state.balance -= action.payload.amount;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    }
  },
});

export const {
  setBalance,
  setTransactions,
  addTransaction,
  setLoading,
  setError,
  clearError
} = walletSlice.actions;

export default walletSlice.reducer;