import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import itemsReducer from "./slices/cafe";
import userReducer from "./slices/user";
import adminReducer from "./slices/admin";
import {toggleReducer} from "./slices/toggleslice";
import cartReducer from './slices/cartSlice';
import posReducer from './slices/posSlice';
import walletReducer from './slices/walletSlice';  // Add this line

export default configureStore({
  reducer: {
    auth: authReducer,
    cafe: itemsReducer,
    user: userReducer,
    admin: adminReducer,
    bool: toggleReducer,
    cart: cartReducer,
    pos: posReducer,
    wallet: walletReducer,  // Add this line
  },
  preloadedState: {
    user: {
      cart: JSON.parse(sessionStorage.getItem("OG_bag")) || [],
      cafe: JSON.parse(sessionStorage.getItem("OG_cafe")) || [],
      order: JSON.parse(sessionStorage.getItem("OG_order")) || [],
    },
    pos: {
      menuItems: {},
      inventoryItems: [],
      orders: [],
      cart: [],
      loading: false,
      error: null,
    },
    wallet: {
      balance: 0,
      transactions: [],
      loading: false,
      error: null,
      lastTransaction: null
    }
  },
});