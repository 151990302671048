import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Select, Form, Typography, Divider, Tabs, Modal, Table } from 'antd';
import { toast } from 'react-toastify';
import { PlusOutlined, BranchesOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

const WorkspaceManagement = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [cafes, setCafes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState({});  // Object with workspace_id as key
  const [selectedBranch, setSelectedBranch] = useState({});  // Object with workspace_id as key
  const [branchModalVisible, setBranchModalVisible] = useState(false);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [newBranch, setNewBranch] = useState({
    name: '',
    description: ''
  });
  const [adminModalVisible, setAdminModalVisible] = useState(false);
const [adminForm] = Form.useForm();
const [workspaceAdmins, setWorkspaceAdmins] = useState({});
const [loadingAdmins, setLoadingAdmins] = useState(false);

const fetchWorkspaceAdmins = async (workspaceId) => {
  try {
    const token = localStorage.getItem('superadminToken');
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspace/${workspaceId}/admins`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const result = await response.json();
    if (response.ok) {
      setWorkspaceAdmins(prev => ({
        ...prev,
        [workspaceId]: result.data
      }));
    }
  } catch (error) {
    toast.error('Error fetching workspace admins');
  }
};

const handleCreateAdmin = async (values) => {
  try {
    setLoadingAdmins(true);
    const token = localStorage.getItem('superadminToken');
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspace/admin`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...values,
        workspace_id: currentWorkspace
      })
    });
    
    const result = await response.json();
    if (response.ok) {
      toast.success('Workspace admin created successfully');
      setAdminModalVisible(false);
      adminForm.resetFields();
      fetchWorkspaceAdmins(currentWorkspace);
    } else {
      toast.error(result.message || 'Failed to create admin');
    }
  } catch (error) {
    toast.error('Error creating workspace admin');
  } finally {
    setLoadingAdmins(false);
  }
};

// Add this new component
const AdminModal = ({ visible, onCancel, form, loading, onFinish }) => (
  <Modal
    title="Create Workspace Admin"
    open={visible}
    onCancel={onCancel}
    footer={null}
  >
    <Form 
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label="Admin Name"
        rules={[{ required: true, message: 'Please enter admin name' }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Enter admin name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter email' },
          { type: 'email', message: 'Please enter a valid email' }
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder="Enter email" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone"
        rules={[
          { required: true, message: 'Please enter phone number' },
          { pattern: /^\d{10}$/, message: 'Please enter a valid 10-digit phone number' }
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder="Enter phone number" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please enter password' },
          { min: 6, message: 'Password must be at least 6 characters' }
        ]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Enter password" />
      </Form.Item>

      <Form.Item className="mb-0 flex justify-end">
        <Button type="default" onClick={onCancel} className="mr-2">
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Create Admin
        </Button>
      </Form.Item>
    </Form>
  </Modal>
);

// Add this admin columns configuration
const adminColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => new Date(date).toLocaleDateString()
  }
];
  // Form states
  const [newWorkspace, setNewWorkspace] = useState({
    name: '',
    description: ''
  });

  const [newAdmin, setNewAdmin] = useState({
    name: '',
    email: '',
    phone: '',
    password: ''
  });

  useEffect(() => {
    fetchWorkspaces();
    fetchCompanies();
    fetchCafes();
  }, []);

  useEffect(() => {
    // Fetch branches for each workspace
    workspaces.forEach(workspace => {
      fetchBranches(workspace._id);
    });
  }, [workspaces]);
const fetchBranches = async (workspaceId) => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspace/${workspaceId}/branches`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      if (response.ok) {
        setBranches(prev => ({
          ...prev,
          [workspaceId]: result.data
        }));
      }
    } catch (error) {
      toast.error('Error fetching branches');
    }
  };

  const createBranch = async () => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspace/${currentWorkspace}/branch`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newBranch)
      });
      
      const result = await response.json();
      if (response.ok) {
        toast.success('Branch created successfully');
        fetchBranches(currentWorkspace);
        setBranchModalVisible(false);
        setNewBranch({ name: '', description: '' });
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error('Error creating branch');
    }
  };

  

  const fetchWorkspaces = async () => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspaces`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      if (response.ok) {
        setWorkspaces(result.data);
      } else {
        toast.error('Failed to fetch workspaces');
      }
    } catch (error) {
      toast.error('Error fetching workspaces');
    }
  };

  const fetchCompanies = async () => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/companies`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      if (response.ok) {
        setCompanies(result.data);
      }
    } catch (error) {
      toast.error('Error fetching companies');
    }
  };

  const fetchCafes = async () => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/cafes`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      if (response.ok) {
        setCafes(result.data);
      }
    } catch (error) {
      toast.error('Error fetching cafes');
    }
  };

  const createWorkspace = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspace`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: newWorkspace.name,
          description: newWorkspace.description
        })
      });
      
      const data = await response.json();
      if (response.ok) {
        toast.success('Workspace created successfully');
        setNewWorkspace({ name: '', description: '' });
        fetchWorkspaces();
      } else {
        toast.error(data.message || 'Failed to create workspace');
      }
    } catch (error) {
      console.error('Error creating workspace:', error);
      toast.error('Error creating workspace');
    } finally {
      setLoading(false);
    }
  };

  const createWorkspaceAdmin = async (workspaceId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspace/admin`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...newAdmin, workspace_id: workspaceId })
      });
      
      if (response.ok) {
        toast.success('Workspace admin created successfully');
        setNewAdmin({ name: '', email: '', phone: '', password: '' });
        fetchWorkspaces();
      } else {
        toast.error('Failed to create workspace admin');
      }
    } catch (error) {
      toast.error('Error creating workspace admin');
    } finally {
      setLoading(false);
    }
  };

  const addEntityToWorkspace = async (workspaceId, entityId, type) => {
    if (!selectedBranch[workspaceId]) {
      toast.error('Please select a branch first');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('superadminToken');
      const endpoint = type === 'company' ? 'add-company' : 'add-cafe';
      
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/superadmin/workspace/${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          workspaceId,
          branchId: selectedBranch[workspaceId],
          [type === 'company' ? 'companyId' : 'cafeId']: entityId
        })
      });
      
      if (response.ok) {
        toast.success(`${type === 'company' ? 'Company' : 'Cafe'} added to branch successfully`);
        fetchWorkspaces();
        fetchBranches(workspaceId);
      } else {
        toast.error(`Failed to add ${type} to branch`);
      }
    } catch (error) {
      toast.error(`Error adding ${type} to branch`);
    } finally {
      setLoading(false);
    }
  };

  const BranchModal = ({ visible, onCancel }) => (
    <Modal
      title="Create New Branch"
      open={visible}
      onOk={createBranch}
      onCancel={onCancel}
    >
      <Form layout="vertical">
        <Form.Item label="Branch Name" required>
          <Input
            value={newBranch.name}
            onChange={(e) => setNewBranch({ ...newBranch, name: e.target.value })}
            placeholder="Enter branch name"
          />
        </Form.Item>
        <Form.Item label="Description">
          <TextArea
            value={newBranch.description}
            onChange={(e) => setNewBranch({ ...newBranch, description: e.target.value })}
            placeholder="Enter branch description"
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );


  return (
    <div className="space-y-6">
      <Card title="Create New Workspace" loading={loading}>
        <Form layout="vertical">
          <Form.Item label="Workspace Name" required>
            <Input
              placeholder="Enter workspace name"
              value={newWorkspace.name}
              onChange={(e) => setNewWorkspace({ ...newWorkspace, name: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea
              rows={4}
              placeholder="Enter workspace description"
              value={newWorkspace.description}
              onChange={(e) => setNewWorkspace({ ...newWorkspace, description: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={createWorkspace} loading={loading}>
              Create Workspace
            </Button>
          </Form.Item>
        </Form>
      </Card>
      {workspaces.map((workspace) => (
      <Card 
        key={workspace._id} 
        title={workspace.name}
        extra={
          <div>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={() => {
                setCurrentWorkspace(workspace._id);
                setBranchModalVisible(true);
              }}
              className="mr-2"
            >
              Add Branch
            </Button>
            <Button 
              type="primary"
              icon={<UserOutlined />}
              onClick={() => {
                setCurrentWorkspace(workspace._id);
                setAdminModalVisible(true);
              }}
            >
              Add Admin
            </Button>
          </div>
        }
      >
          <Tabs defaultActiveKey="branches">
            <TabPane 
              tab={<span><BranchesOutlined />Branches</span>}
              key="branches"
            >
              <div className="mb-4">
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select Branch"
                  value={selectedBranch[workspace._id]}
                  onChange={(value) => setSelectedBranch(prev => ({
                    ...prev,
                    [workspace._id]: value
                  }))}
                >
                  {branches[workspace._id]?.map(branch => (
                    <Option key={branch._id} value={branch._id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </div>

              {selectedBranch[workspace._id] && (
                <>
                  <Divider>Add Company to Branch</Divider>
                  <Form.Item>
                    <Select
                      placeholder="Select company"
                      style={{ width: '100%' }}
                      onChange={(value) => addEntityToWorkspace(workspace._id, value, 'company')}
                    >
                      {companies.map((company) => (
                        <Option key={company._id} value={company._id}>
                          {company.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Divider>Add Cafe to Branch</Divider>
                  <Form.Item>
                    <Select
                      placeholder="Select cafe"
                      style={{ width: '100%' }}
                      onChange={(value) => addEntityToWorkspace(workspace._id, value, 'cafe')}
                    >
                      {cafes.map((cafe) => (
                        <Option key={cafe._id} value={cafe._id}>
                          {cafe.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* Display branch details */}
                  <Card type="inner" title="Branch Details">
                    {branches[workspace._id]?.find(b => b._id === selectedBranch[workspace._id])?.companies?.map(company => (
                      <div key={company._id} className="mb-2">
                        <Title level={5}>{company.name}</Title>
                        <p>Companies: {company.cafe_count || 0}</p>
                      </div>
                    ))}
                  </Card>
                </>
              )}
            </TabPane>
            <TabPane
            tab={<span><UserOutlined />Admins</span>}
            key="admins"
          >
            <Table
              columns={adminColumns}
              dataSource={workspaceAdmins[workspace._id] || []}
              rowKey="_id"
              loading={loadingAdmins}
            />
          </TabPane>
          </Tabs>
        </Card>
      ))}

      <BranchModal 
        visible={branchModalVisible}
        onCancel={() => {
          setBranchModalVisible(false);
          setNewBranch({ name: '', description: '' });
        }}
      />
       <AdminModal 
      visible={adminModalVisible}
      onCancel={() => {
        setAdminModalVisible(false);
        adminForm.resetFields();
      }}
      form={adminForm}
      loading={loadingAdmins}
      onFinish={handleCreateAdmin}
    />
    </div>
  );
};

export default WorkspaceManagement;

