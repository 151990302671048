  // components/workspace/WorkspaceDashboard.js
  import React, { useState, useEffect } from 'react';
  import { Layout, Menu, Card, Table, Typography, Button, Modal } from 'antd';
  import { BranchesOutlined, LogoutOutlined } from '@ant-design/icons';
  import { useNavigate } from 'react-router-dom';
  import WorkspaceLayout from './workspaceLayout';
import SalesGraph from './SalesGraph';

  const { Header, Content, Sider } = Layout;
  const { Title } = Typography;

  const WorkspaceDashboard = () => {
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branchDetails, setBranchDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const workspaceInfo = JSON.parse(localStorage.getItem('workspaceInfo') || '{}');

    useEffect(() => {
      fetchBranches();
    }, []);

    const fetchBranches = async () => {
      try {
        const token = localStorage.getItem('workspaceAdminToken');
        console.log("Sending token:", token); // Debug log
        
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/workspace/branches`, {
          headers: {
            'x-auth-token': token,  // Make sure you're using the right header name
            'Content-Type': 'application/json'
          }
        });
        
        const data = await response.json();
        console.log("Response data:", data); // Debug log
        
        if (response.ok) {
          setBranches(data.data);
        } else {
          console.error("Error response:", data); // Debug log
          // Handle error appropriately
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    const fetchBranchDetails = async (branchId) => {
      try {
        setLoading(true);
        const token = localStorage.getItem('workspaceAdminToken');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/workspace/branch/${branchId}`, {
          headers: {
              'x-auth-token': token  
          }
        });
        
        const data = await response.json();
        if (response.ok) {
          setBranchDetails(data.data);
          setSelectedBranch(branchId);
        }
      } catch (error) {
        console.error('Error fetching branch details:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleLogout = () => {
      localStorage.removeItem('workspaceAdminToken');
      localStorage.removeItem('workspaceInfo');
      navigate('/workspace/login');
    };
   
    const branchColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Sales',
        dataIndex: 'total_sales',
        key: 'total_sales',
      },
      {
        // title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <Button type="primary" onClick={() => navigate(`/workspace/${record._id}/board`)}>
            Check Details
          </Button>
        ),
      },
    ];

    

    return (
      <WorkspaceLayout handleLogout={handleLogout}>
        <Layout>
          <div className="w-full flex items-center gap-x-2 mx-8 mt-8">
            <SalesGraph totalSales={11310} />
            <div
              className="bg-[#FFF2AF] p-4 rounded-lg shadow-md max-w-sm mx-2 border border-[#000000]"
              style={{ boxShadow: "3px 3px 0px 0px #000000" }}
            >
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h2 className="text-4xl font-bold">15</h2>
                  <p className="text-gray-600 text-sm">Total Feedback</p>
                </div>
                <div>
                  <h2 className="text-4xl font-bold">4.2</h2>
                  <p className="text-gray-600 text-sm">Avg. Rating</p>
                </div>
              </div>
              <hr className="border-[#FFD700] mb-4" />
              <div className=" mb-2">
                <p className="text-xs ">Detailed</p>
              </div>
              <div className="grid grid-cols-3 gap-4 text-center">
                <div>
                  <div className="text-sm font-bold flex gap-1">
                    {" "}
                    <div className="">53</div>{" "}
                    <spam className="font-medium text-sm">Positive</spam>
                  </div>
                  <div className="flex gap-1">
                    <div className="text-2xl">😀</div>
                    <div className="text-xl font-bold">
                      28.9%
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold flex gap-1">
                    <div className="">10</div>{" "}
                    <spam className="font-medium">Negative</spam>
                  </div>

                  <div className="flex gap-1">
                    {" "}
                    <div className="text-2xl">😞</div>
                    <div className="text-xl font-bold">
                      0.23%
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold flex gap-1">
                    <div className="">45</div>{" "}
                    <spam className="font-medium">Neutral</spam>
                  </div>
                  <div className="flex gap-1">
                    {" "}
                    <div className="text-2xl">😐</div>
                    <div className="text-xl font-bold">
                      53.6%
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <Content className="p-6">
            <Title level={2}>{workspaceInfo.name} Workspaces</Title>
            <Card
              className="mb-6"
            >
              <Table
                columns={branchColumns}
                dataSource={branches}
                rowKey="_id"
                loading={loading}
              />
            </Card>

            {/* {branchDetails && (
              <Modal
                title={`Branch: ${branchDetails.name}`}
                open={!!selectedBranch}
                onCancel={() => setSelectedBranch(null)}
                width={1000}
                footer={null}
              >
                <div className="space-y-6">
                  <Card
                    title="Companies"
                    size="small"
                  >
                    <Table
                      columns={companyColumns}
                      dataSource={branchDetails.companies}
                      rowKey="_id"
                      pagination={false}
                    />
                  </Card>

                  <Card
                    title="Cafes"
                    size="small"
                  >
                    <Table
                      columns={cafeColumns}
                      dataSource={branchDetails.cafes}
                      rowKey="_id"
                      pagination={false}
                    />
                  </Card>
                </div>
              </Modal>
            )} */}
          </Content>
        </Layout>
      </WorkspaceLayout>
    );
  };

  export default WorkspaceDashboard;